import classname from 'classnames';
import hero_image from '../public/images/heroimage.png';
import signature from '../public/images/signature.png';
import mirror_selfie from '../public/images/mirror-selfie.JPG';
import React from 'react';

class Home extends React.Component  {
    render() {
        return (
            <div className={
                classname(
                    "Home",
                    "z-40"
                )
            }>
                <div className={
                    classname(
                        "flex",
                        "justify-center",
                        "relative"
                    )
                }>
                    <div className="md:hidden">
                        <div className="flex justify-center py-4 relative z-0">
                            <img className="" src={signature} alt="signature" />
                        </div>
                        <div className="flex justify-center px-4 pb-4">
                            <img className="" src={mirror_selfie} style={{maxHeight: "250px"}} alt="mirror-selfie" />
                        </div>
                        <p className="text-2xl px-8 py-4 text-center text-secondary leading-tight">
                            I'm a Los Angeles based fashion student and freelance digital marketing manager.
                        </p>
                    </div>

                    <div className="hidden md:flex pt-12 md:mt-0 md:pt-6">
                        <img className="" src={hero_image} alt="hero"/>
                    </div>
                </div>
            </div>
        );
    }
}

export default Home;