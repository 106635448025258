import React from 'react';
import { BrowserRouter, Route, useLocation, matchPath } from 'react-router-dom';
import { CSSTransition } from "react-transition-group";

import Contact from './views/Contact';
import Home from './views/Home';
import Work from './views/Work';

import Navigation from './components/Navigation';
const routes = [
    { path: '/', label: 'home', Component: Home },
    { path: '/work', label: 'work', Component: Work },
    { path: '/contact', label: 'contact', Component: Contact }
];



class App extends React.Component  {  

    render() {
        return (
            <BrowserRouter>
                <div className="App content">
                    <div className="container mx-auto">
                        <Navigation routes={routes}/>
                        {
                            routes.map(
                                ({ path, Component }) => (
                                    <Route key={path} exact path={path}>
                                        {
                                            <CSSTransition
                                                in={true}
                                                appear={true}
                                                timeout={300}
                                                classNames="fade"
                                            >
                                                <div className="">
                                                    <Component />
                                                </div>
                                            </CSSTransition>
                                        }
                                    </Route>
                                )
                            )
                        }
                        <div className="text-secondary container text-right pr-4 md:pr-4 lg:pr-0 z-50 items-end">
                            Coded with &#9829;/&#9834; by&nbsp;<a target="_blank" rel="noopener noreferrer" className="italic" href="https://www.instagram.com/kvngo/">Kevin Ngo</a>
                        </div>
                    </div>
                </div>
            </BrowserRouter>
        );
    }
}

export default App;