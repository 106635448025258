import classname from 'classnames';
import React from 'react';
import ScrollSnap from 'scroll-snap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight, faChevronUp } from '@fortawesome/free-solid-svg-icons';

function callback() {
    console.log('snapped')
}

class Work extends React.Component  {

    container = React.createRef()

    bindScrollSnap() {
        const element = this.container.current
        const snapElement = new ScrollSnap(element, {
        snapDestinationY: '90%',
        })

        snapElement.bind(callback)
    }

    componentDidMount() {
        this.bindScrollSnap()
    }

    scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }

    render() {
        return (
            <div 
            id="container" ref={this.container}
            className={
                classname(
                    "Work",
                    "container"
                )
            }>
                <div className="flex justify-center items-center relative pt-8 md:pt-16">
                    <div className="bg-light w-11/12 md:w-6/12 border-2 border-secondary">
                        <div className="px-20 pb-4 pt-8">
                            <div className="text-6xl text-bold text-secondary text-center pt-2">1</div>
                            <div className="text-3xl text-bold text-secondary text-center pt-2 border-b-2 pb-4">Social Media Management</div>
                        </div>
                        <div className="md:text-xl text-secondary text-center py-4 px-20">Plan monthly calendars for social media platform posting</div>
                        <div className="md:text-xl text-secondary text-center py-4 px-20">Design brand content for stories and feed</div>
                        <div className="md:text-xl text-secondary text-center py-4 px-20">Engage with followers</div>
                        <div className="md:text-xl text-secondary text-center py-4 px-20">Plan marketing campaigns for new releases</div>
                        <div className="md:text-xl text-secondary text-center py-4 px-20 pb-12">Build community with other small, independent brands</div>
                    </div>
                </div>

                <div className="flex justify-center items-center relative pt-8 md:pt-16">
                    <div className="bg-light w-11/12 md:w-6/12 border-2 border-secondary">
                        <div className="px-12 pb-4 pt-8">
                            <div className="text-6xl text-bold text-secondary text-center pt-2">2</div>
                            <div className="text-3xl text-bold text-secondary text-center pt-2 border-b-2 pb-4">Email Design</div>
                        </div>
                        <div className="md:text-xl text-secondary text-center py-4 px-20">Create eye-catching email content released to subscribers</div>
                        <div className="md:text-xl text-secondary text-center py-4 px-20">Converting viewership to orders</div>
                        <div className="md:text-xl text-secondary text-center py-4 px-20">Generate nearly $4000 in revenue in one month period through email marketing</div>
                        <div class="text-center px-6 pb-12"><a href="https://portfolium.com/MandyChen5" target="_blank"><FontAwesomeIcon  className="text-3xl text-secondary" icon={faAngleRight} /></a></div>
                    </div>
                </div>
                
                <div className="flex justify-center items-center relative pt-8 md:pt-16">
                    <div className="bg-light w-11/12 md:w-6/12 border-2 border-secondary">
                        <div className="px-12 pb-4 pt-8">
                            <div className="text-6xl text-bold text-secondary text-center pt-2">3</div>
                            <div className="text-3xl text-bold text-secondary text-center pt-2 border-b-2 pb-4">Writing</div>
                        </div>
                        <div className="md:text-xl text-secondary text-center py-4 px-20">Written for brands' "About Us" pages, blog writings, social media copy, and product descriptions</div>
                        <div className="md:text-xl text-secondary text-center py-4 px-20">(I was also an English tutor for 2 years+ at a community college!)</div>
                        <div class="text-center px-6 pb-8"><a href="https://portfolium.com/MandyChen5" target="_blank"><FontAwesomeIcon  className="text-3xl text-secondary" icon={faAngleRight} /></a></div>
                    </div>
                </div>

                <div className="flex justify-center items-center relative pt-8 md:pt-16">
                    <div className="bg-light w-11/12 md:w-6/12 border-2 border-secondary">
                        <div className="px-12 pb-4 pt-8">
                            <div className="text-6xl text-bold text-secondary text-center pt-2">4</div>
                            <div className="text-3xl text-bold text-secondary text-center pt-2 border-b-2 pb-4">Content Creation</div>
                        </div>
                        <div className="md:text-xl text-secondary text-center py-4 px-20">Creating branded assets for website banners, newsletters, Instagram stories and posts, and other media platforms</div>
                        <div className="md:text-xl text-secondary text-center py-4 px-20">
                            <span className="border-b-2 border-secondary">Skills Used: <br></br></span>
                            <div className="pt-4">
                                Photoshop<br></br>
                                Illustrator<br></br>
                                Photography<br></br>
                                Video editing<br></br>
                            </div>
                        </div>
                        <div class="text-center px-6 pb-8"><a href="https://portfolium.com/MandyChen5" target="_blank"><FontAwesomeIcon  className="text-3xl text-secondary" icon={faAngleRight} /></a></div>
                    </div>
                </div>

                <div className="flex justify-center items-center relative pt-8 md:pt-16">
                    <div className="bg-light w-11/12 md:w-6/12 border-2 border-secondary">
                        <div className="px-12 pb-4 pt-8">
                            <div className="text-6xl text-bold text-secondary text-center pt-2">5</div>
                            <div className="text-3xl text-bold text-secondary text-center pt-2 border-b-2 pb-4">Shopify Experience</div>
                        </div>
                        <div className="md:text-xl text-secondary text-center py-4 px-20">Website maintenance within the Shopify e-commerce platform</div>
                        <div className="md:text-xl text-secondary text-center py-4 px-20 pb-8">
                            <span className="border-b-2 border-secondary">Skills Used: <br></br></span>
                            <div className="pt-4">
                                UX design<br></br>
                                Order fulfillment<br></br>
                                Customer engagement<br></br>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex justify-center items-center relative pt-8 md:pt-16 pb-4">
                    <div className="bg-light w-11/12 md:w-6/12 border-2 border-secondary">
                        <div className="px-12 pb-4 pt-8">
                            <div className="text-6xl text-bold text-secondary text-center pt-2">6</div>
                            <div className="text-3xl text-bold text-secondary text-center pt-2 border-b-2 pb-4">Paid Advertisement</div>
                        </div>
                        <div className="md:text-xl text-secondary text-center py-4 px-20">Scheduling paid advertisments for launches, restocks, and other campaigns</div>
                        <div className="md:text-xl text-secondary text-center py-4 px-20 pb-8">
                            <span className="border-b-2 border-secondary">Skills Used: <br></br></span>
                            <div className="pt-4">
                                Data analysis<br></br>
                                Targeted marketing<br></br>
                                Facebook Ads Manager<br></br>
                                A/B Testing<br></br>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="up-arrow" className="static text-center py-4 md:fixed top-NinetyVh right-TwentySevenVw cursor-pointer" onClick={() => this.scrollToTop()}>
                    <FontAwesomeIcon  className="text-4xl text-secondary" icon={faChevronUp} />
                </div>
            </div>
        );
    }
}

export default Work;