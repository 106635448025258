import { NavLink } from 'react-router-dom';
import React from 'react';

class Navigation extends React.Component  {
    constructor(props) {
        super(props);
        this.state = { 
            currentPage: 'home',
            open: false
        }
    }

    changePage(page) {
        this.setState({
            currentPage: page,
            open: !this.state.open
        });
    }

    openMobileMenu() {
        this.setState({
            open: !this.state.open
        });
    }

    render() {
        return (
            <div className="Navigation">
                <div className="container sm:px-0 md:px-8">

                    {/* Web */}
                    <div className="hidden desktop md:flex justify-evenly pt-10 md:border-b-2 md:border-secondary md:pb-4">
                    {
                        this.props.routes.map((route, i) => {
                            let activeRoute = this.state.currentPage === route.label;
                            return (
                                <div>
                                    <NavLink 
                                        strict 
                                        exact 
                                        to={route.path} 
                                        key={route.path} 
                                        onClick={ () => this.changePage(route.label) } 
                                        className={`font-medium outline-none select-none text-secondary text-center text-3xl px-8 py-9 my-5 pb-0 mb-0 border-2 hover:rounded-oval hover:border-secondary ${activeRoute ? "border-2 rounded-oval border-secondary" : "border-transparent"}`}>
                                            {route.label}
                                    </NavLink>
                                </div>
                            )
                        })
                    }
                    </div>


                    {/* Mobile */}
                    <div className="flex flex-wrap py-2 md:hidden z-50">
                        <div className="w-full">
                            <nav className="relative flex flex-wrap items-center justify-between py-3">
                                <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
                                    <div className="w-full relative flex justify-between md:w-auto px-4 md:static md:block md:justify-start z-50">
                                        <a className="text-lg font-bold leading-relaxed inline-block mr-4 py-2 whitespace-no-wrap uppercase text-secondary" href="/">
                                            Mandy Chen
                                        </a>
                                        <button onClick={() => this.openMobileMenu()} className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block md:hidden outline-none focus:outline-none" type="button">
                                            <span className="block relative w-6 h-px rounded-sm bg-secondary"></span>
                                            <span className="block relative w-6 h-px rounded-sm bg-secondary mt-1"></span>
                                            <span className="block relative w-6 h-px rounded-sm bg-secondary mt-1"></span>
                                        </button>
                                    </div>
                                    <div className={`flex lg:flex-grow pl-4 ${this.state.open ? "block" : "hidden"}`}>
                                        <ul className="flex flex-col lg:flex-row list-none ml-auto text-left absolute z-10">
                                            {
                                                this.props.routes.map((route, i) => {
                                                    return (
                                                        <li className="nav-item">
                                                            <NavLink 
                                                                strict 
                                                                exact 
                                                                to={route.path} 
                                                                key={route.path} 
                                                                onClick={ () => this.changePage(route.label) } 
                                                                className={`"px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-secondary hover:opacity-75"`}>
                                                                    {route.label}
                                                            </NavLink>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

export default Navigation;