import classname from 'classnames';
import notepad from '../public/images/notepad.png';
import React from 'react';

class Contact extends React.Component  {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false
        }
    }

    handleChange(event) {
        let target = event.target;
        let name = target.name;
        let value = target.value;

        this.setState({
            [name]: value
        });
    }

    sendEmail() {
        this.setState({
            isLoading: true
        });

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
                "Name": this.state.Name,
                "Email": this.state.Email,
                "Message": this.state.Message
            })
        };

        fetch('https://mandychen-email.herokuapp.com/email', requestOptions)
        .then(data => this.setState({ isLoading: false }));
    }

    render() {
        return (
            <div className={
                classname(
                    "Contact",
                    "relative",
                    "z-40"
                )
            }>
                <div className={
                    classname(
                        "flex",
                        "justify-center",
                        "sm:mt-8",
                        "md:mt-0",
                        "md:pt-12",
                        "relative"
                    )
                }>
                    <span className="leave-a-note absolute text-secondary text-3xl pt-4">
                        Leave a note.
                    </span>
                    <div className="px-4 ml-6 md:ml-0 contact-form w-100 z-50 pt-40">
                        <div class="mb-3 pt-0 max-w-md">
                            <input type="text" name="Name" onChange={(event) => this.handleChange(event)} placeholder="Name" class="px-3 py-4 placeholder-secondary text-secondary relative bg-white bg-white rounded text-base border border-gray-400 outline-none focus:outline-none w-full"/>
                        </div>
                        <div class="mb-3 pt-0 max-w-md">
                            <input type="text" name="Email" onChange={(event) => this.handleChange(event)} placeholder="Email" class="px-3 py-4 placeholder-secondary text-secondary relative bg-white bg-white rounded text-base border border-gray-400 outline-none focus:outline-none w-full"/>
                        </div>
                        <div className="mb-3 pt-0 max-w-md">
                            <textarea placeholder="Message" onChange={(event) => this.handleChange(event)} name="Message" className="rounded-md border-pink-600 px-3 py-4 placeholder-secondary text-secondary relative bg-white bg-white rounded text-base border border-gray-400 outline-none focus:outline-none w-full" id="message" rows="10" cols="30">

                            </textarea>
                        </div>
                        <div className="mb-3 pt-10 max-w-md text-right">
                            <button class="px-4 bg-transparent text-secondary border-secondary border-2 uppercase rounded shadow hover:shadow-md outline-none focus:outline-none" type="button">
                                {
                                    this.state.isLoading &&
                                    <svg class="animate-spin h-5 w-4 float-left w-16" viewBox="0 0 24 24">
                                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path><circle class="opacity-0" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                    </svg>
                                }
                                {
                                    !(this.state.isLoading) &&
                                    <span className="text-sm" onClick={() => this.sendEmail()}>
                                        {/* <FontAwesomeIcon icon={faHeart} />  */}
                                        Send
                                    </span>
                                }
                            </button>
                        </div>
                    </div>
                    <img class="absolute z-40 md:max-w-lg mt-16 md:mt-8 pt-6 md:pt-0" src={notepad} alt="notepad" />
                </div>
            </div>
        );
    }
}

export default Contact;